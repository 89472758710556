.ui.container {
  margin-top: 15px;
}

.btn-holder {
  height: 30px;
}

.input_label {
  width: 150px;
  text-align: center;
}

.link {
  cursor: pointer !important;
}