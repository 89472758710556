.row
  display: grid
  grid-auto-flow: column
  align-items: start
  grid-auto-columns: 1fr
  margin-bottom: 1rem

.cell
  font-weight: 500

.editCell
  display: flex
  justify-content: right
